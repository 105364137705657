.events {
  display: flex;
  justify-content: center;

  background: linear-gradient(
    var(--secomp-dark-blue),
    var(--secomp-light-blue)
  );
  padding: 2rem 0 4rem;
  width: 100dvw;
}

.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  height: fit-content;
}

.reminderTextXp {
  font-weight: 300;
  font-family: 1.5rem;
  color: #fff;
  font-family: "Montserrat";
  text-align: center;
}

.spanHighlight {
  color: var(--secomp-yellow);
}

.reminderTextSubscribe {
  font-weight: bold;
  font-size: 2.25rem;
  color: #fff;
  font-family: "Montserrat";
  text-align: center;

}

.toast {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 1rem;
  width: 280px;
  border-radius: 999px;
}

@media (max-width: 1000px) {
  .cardsContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .cardsContainer {
    padding: 0;
  }
}

@media (max-width: 680px) {
  .events {
    padding: 2rem 0 4rem;
  }

  .cardsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .reminderTextXp {
    font-weight: 300;
    font-family: 1rem;
  }

  .reminderTextSubscribe {
    font-weight: bold;
    font-size: 1.5rem;
  }
}

