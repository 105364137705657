.subscribeContainer {
  height: 100dvh;
}

.background {
  width: 100%;
  max-height: 33%;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 8rem;
}

.logoHorizontal {
  max-width: 704px;
  min-width: 196px;
}

.logoVertical {
  max-width: 704px;
  min-width: 196px;
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 8rem;
}

.successfulContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  max-width: 344px;
  min-width: 232px;

  font-family: "Montserrat";
}

.icon {
  height: 96px;
  width: 96px;
}

.successfulTitle {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: var(--secomp-green);
}

.errorTitle {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: var(--secomp-error);
}

.successfulText {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 300;
  color: white;
}

.successfulBtn {
  cursor: pointer;
  transition: 0.2s;

  padding: 0.75rem 1.5rem;

  border: none;
  font-size: 1.5rem;
  font-weight: bold;

  background-color: var(--secomp-yellow);
  color: white;
}

.successfulBtn:hover {
  scale: 110%;
}

.successfulBtn:focus {
  outline: none;
  scale: 110%;
}

.sponsors {
  display: flex;
  align-items: center;
  gap: 2rem;

  margin: 0 auto;
  padding: 10rem 0 4rem;
  width: 100%;
  max-width: 750px;
}

.sponsorLogo {
  object-fit: none;
  width: fit-content;
  max-height: 128px;
}

.imcHorizontalLogo {
  display: none;
  max-width: 344px;
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .logoHorizontal {
    width: 55%;
  }
  .logoVertical {
    width: 55%;
  }
}

@media (max-width: 900px) {
  .content {
    flex-direction: column;
    gap: 2.5rem;
  }

  .successfulTitle {
    font-size: 1.5rem;
  }

  .successfulText {
    font-size: 1rem;
  }

  .logoVertical {
    display: block;
  }

  .logoHorizontal {
    display: none;
  }

  .sponsorLogo {
    display: none;
  }

  .imcHorizontalLogo {
    display: block;
  }
}
