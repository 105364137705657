
.localizacao {
  display: flex;

  background: linear-gradient(180deg, #345D8F 44.06%, #2D3E51 100%);

  color: #fff;
  font-style: normal;
  margin-bottom: 10rem;
}

.conteudo {
  width: 100vmax;
  text-align: center;

}

.title {
  margin: 2% auto;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;

  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.map {
  width: 81.25%;
  height: 414px;

  border: 3px solid #F3BC18;
  filter: drop-shadow(4px 6px 15px rgba(0, 0, 0, 0.25));
  border-radius: 50px;
}

.address {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2rem;
}

.img {
  height: 81px;
  width: 91px;
}

.infosTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;
}

.infosAdressUnifei {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
}

.infosAdressPlace {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
}

.infosAdressCity {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
}

@media (max-width: 1200px) {
  .localizacao {
    padding-top: 200px;
  }
}

@media (max-width: 940px) {
  .localizacao {
    padding-top: 5rem;
  }
}
