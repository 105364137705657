.accordionItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: .5rem;

  width: 100%;
  padding: 1rem 0;
}

.titleContainer img {
  cursor:pointer;
}

.contentContainerOpen {
  font-size: 1.125rem;
  color: var(--secomp-dark-blue);

  border: var(--secomp-yellow) solid 4px;
  border-radius: 0 0 1rem 1rem;
  background-color: white;
  padding: 1rem;
  transition: max-height .4s ease-in-out, opacity 0.4s ease-in-out;

  max-height: 500px;
  width: 100%;
}

.contentContainerOpen a {
  color: var(--secomp-dark-blue);
}

.contentContainerOpen a:hover {
  color: var(--secomp-yellow);
}

.contentContainerClose {
  max-height: 0;
  opacity: 0;
  width: 100%;
  border-top: var(--secomp-yellow) solid 4px;
}

.contentContainerClose p {
  display: none;
  cursor: default;
}

.divisor {
  height: .25rem;
  width: 100%;
  background-color: var(--secomp-yellow);
}