.subscribeContainer {
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

.background {
  width: 100%;
  max-height: 33%;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorImg {
  width: 96px;
}

.errorTitle {
  color: var(--secomp-error);
  font-family: 'Montserrat';
  font-size: 3rem;
  font-weight: bold;
  text-align: center;

  margin-bottom: .5rem;
}

.errorMessage {
  max-width: 360px;
  color: white;

  text-align: center;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 1.5rem;

  margin-bottom: .5rem;
}

.errorLog {
  width: 100% ;
  color: white;

  text-align: center;
  text-decoration: underline;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 1.5rem;
}

@media (max-width: 400px) {
  .errorMessage {
    font-size: 1rem;
  }

  .errorLog {
    font-size: 1.25rem;
  }
}