.before {
  display: inline-block;
  content: "";
  width: 30%;
  height: 0;
  border: #a9a996;
  background-color: #a9a996;
  border: 1px solid #a9a996;
  opacity: 0.5;
  border-radius: 20px;
}

.orgLista {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2% 0;
}

.titulo {
  color: white;

  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  margin: 1.5rem 0 .5rem 0;
  line-height: 41px;

  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.lista {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  column-gap: 2rem;

  font-family: "Montserrat";
  color: white;
  line-height: 20px;
  list-style-type: none;
}

.lista li{
  text-transform: capitalize;
}


@media (max-width: 750px) {
  .lista {
    font-size: 16px;
  }
}

@media (max-width: 750px) {
  .lista {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

}
