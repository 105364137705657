.footer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  background: #2d3e51;
  border: 1px solid #234a79;

  color: #ffffff;
  font-style: normal;

  width: 100%;
}

.copyright {
  background: #2d3e51;
  box-shadow: 0px -3.3px 8.25px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 1rem 0
}

.img {
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
}