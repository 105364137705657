.navbarContainer {
  position: fixed;
  display: flex;
  justify-content: center;

  width: 100%;
  height: fit-content;
  z-index: 999;

  background: #2d3e51;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.link {
  text-decoration: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 0 5rem;
}

.navLogo {
  scale: 88%;
}

.active {
  width: fit-content;
}

.logo {
  height: 80px;
}

.navMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;

  flex: 1;
  gap: 20px;
}


.navItem {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 28px;
  color: #fff;
}

.navItem:hover {
  color: #f3bc18;
  text-decoration-line: none;
  cursor: pointer;
}

.burgerBtn {
  display: none;
  transition: 0.2s;
  background: none;
  border: none;
}

.burgerBtn:hover {
  scale: 110%;
}

.showMenu {
  width: 100%;
  position: absolute;
  top: 80px;
}

.hideMenu {
  max-height: 0;
  display: none;
}

.verticalMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  color: #fff;
  background-color: var(--secomp-dark-blue);
  font-size: 1.125rem;

  border-bottom: 1px solid var(--secomp-yellow);
}

.menuItem {
  padding: .5rem 0;
  width: 100%;
  transition: 0.2s;
  text-decoration: none;
  cursor: pointer;
}

.menuItem:hover {
  color: var(--secomp-yellow);
}

.menuText {
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 1rem;
  padding: .5rem 0;
}

@media (min-width: 940px) {
  .showMenu {
    display: none;
  }
}

@media (max-width: 940px) {
  .navMenu {
    display: none;
  }

  .burgerBtn {
    display: block;
  }
}


@media (min-width: 680px) {
  .navLogo {
    transition: 0.3s;
  }
  .navLogo:hover {
    scale: 100%;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0 1rem;
  }

  .navItem{
    gap: 0.25rem;
    line-height: 1rem;
  }
  .navMenu a:nth-child(2) > span {
    display: none;
  }
}

@media (max-width: 400px) {
  .navItem {
    font-size: .75rem;
  }
}
