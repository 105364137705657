.faqContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: bold;
  color: white;

  background-color: var(--secomp-light-blue);
  padding: 0 8rem 2rem;
}
.underline {
  cursor: pointer;
  text-decoration: underline;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;
  text-align: center;

  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 800px) {
  .faqContainer {
    padding: 0 4rem 2rem;
  }
}

@media (max-width: 400px) {
  .faqContainer {
    padding: 0 2rem 2rem;
  }
}