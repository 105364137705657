.sobre {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0;
  padding: 10rem 0;
}

.content {
  display: grid;
  width: 100%;
  justify-items: center;
  grid-template-areas:
    "img sobre"
    "subContentBG subContentBG"
    "subContentBG subContentBG";
}

.image {
  grid-area: img;
  max-width: 560px;
  min-width: 300px;

  filter: drop-shadow(6px 4px 15px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
  width: 90%;
}

.texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: sobre;

  max-width: 469px;
  row-gap: 20px;

  font-style: normal;
  color: #fff;
  width: 80%;
}

.linha p:before {
  content: "";
  display: block;
  width: 70px;
  border: 3px solid;
  border-radius: 3px;
  background: #fff;
  margin-bottom: 20px;
}

.title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  margin: 0;
}

.subtitle {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;
}

.text {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  white-space: pre-wrap;
}

.subContentBG {
  display: flex;
  padding: 5rem 0;
  width: 100vw;
  grid-area: subContentBG;
  justify-content: center;
  align-items: center;

  background-image: url("../images/background/about_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.subContent {
  display: grid;
  grid-template-areas:
    "hist event"
    "ong ong";
  justify-items: center;
  align-items: center;
  max-width: 1200px;
  padding: 8rem 0 12rem;
  row-gap: 8rem;
}

.history {
  grid-area: hist;
  display: flex;
  flex-direction: column;

  font-style: normal;
  color: var(--secomp-dark-blue);

  width: 80%;
  max-width: 469px;
}

.event {
  grid-area: event;
  display: flex;
  flex-direction: column;

  font-style: normal;
  color: var(--secomp-dark-blue);
  width: 80%;
  max-width: 469px;
}

.ong {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: ong;
  color: var(--secomp-dark-blue);
  gap: 1rem;
  padding: 8rem 2rem 0;
}

.ong a {
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-weight: bold;

  width: fit-content;
  transition: 0.2s;
}

.ong a:hover {
  color: var(--secomp-dark-blue);
}

.description {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.description img {
  height: 150px;
}

.description p {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (max-width: 1020px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    padding: 0;
  }

  .ong {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .subContent {
    grid-template-areas: 
      "hist hist"
      "event event"
      "ong ong"
    ;
    row-gap: 1rem;
    padding: 8rem 0 8rem;
  }

  .event {
    padding-bottom: 12rem;
  }

  .ong {
    padding: 4rem 0 0;
  }

  .description {
    flex-direction: column;
  }

  .description p {
    font-size: 1rem;
  }
}
