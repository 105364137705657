.conteudoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 30px;
  padding-top: 28px;
}

.conteudo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

a {
  color: #f3bc18;
}

a:hover {
  color: white;
}

.grid6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content 1fr;
  column-gap: 2.52%;
  row-gap: 3rem;
  padding: 28px 0;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 370px;
}

.nome::before {
  display: inline-block;
  content: "";
  width: 70px;
  height: 0px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
}

.nome {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;
}

i {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

b {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.descricaoContainer {
  height: fit-content;
}

.descricaoTitulo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.arrow {
  padding-right: 10px;
}

.descricao {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

.speakerInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  font-weight: bold;

  transition: 0.2s;
}

.speakerInfo:hover {
  scale: 102%;
}

.speakerPhoto {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.highlight {
  margin-top: .5rem;
  font-weight: bold;
}

@media only screen and (max-width: 1100px) {
  .conteudoContainer {
    padding: 1%;
  }

  .grid {
    column-gap: 15px;
    row-gap: 50px;
  }

  .descricao {
    font-size: 15px;
  }
}

@media only screen and (min-width: 781px) and (max-width: 1000px) {
  .grid {
    row-gap: 10px;
  }
}

@media only screen and (max-width: 780px) {
  .grid {
    row-gap: 2px;
    column-gap: 5px;
  }
}

@media (max-width: 1400px) {
  .grid {
    width: 80vw;
  }
  .conteudoContainer {
    width: auto;
  }
}

@media (max-width: 600px) {
  .grid6 {
    grid-template-columns: 1fr;
    grid-template-rows: none;
    gap: 40px;
    height: 600px;
  }
}
