.datasContainer {
  width: 350px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 29px;

  background: #2d3e51;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
}

.datasContainer:hover {
  background-color: #2e517b;
}

.datasContainerInicio {
  border-radius: 20px 0px;
}

.datasContainerMeio {
  border-radius: 0px 20px 20px 0px;
}

.datasContainerFim {
  border-radius: 0px 20px;
}

.dataSelecionada {
  width: 401px;
  height: 130px;
  background: #345d8f;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
}

.dataSelecionada:hover {
  background: #345d8f;
}

.dataTitulo {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 26px;
  line-height: 32px;
}

.dataOrganizador {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
}

.dataDescricao {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
}

@media (max-width: 1080px) {
  .datasContainer {
    width: 100%;
  }

  .dataTitulo {
    text-align: center;
  }

  .datasContainerInicio {
    border-radius: 20px 0 0 0;
  }
  
  .datasContainerMeio {
    border-radius: 0;
  }
  
  .datasContainerFim {
    border-radius: 0 20px 0 0;
  }
}

@media only screen and (max-width: 895px) {
  .datasContainer {
    width: 200px;
    padding-left: 5px;
  }

  .dataSelecionada {
    width: 230px;
  }

  .dataTitulo {
    font-family: "Montserrat";
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
  }

  .dataOrganizador {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
  }

  .dataDescricao {
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
  }
}

@media (max-width: 1400px) {
  /* For mobile phones: */
  .datasContainer {
    width: 90%;
    padding-left: 5px;
  }

  .dataSelecionada {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .dataTitulo {
    font-size: 1rem;
  }
  .day {
    display: none;
  }
}
