.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.title {
  font-size: 2rem;
  font-weight: bold;
}

.successfulMessage {
  color: var(--secomp-green);
}

.errorMessage {
  color: var(--secomp-error);
}

.errorDescription {
  font-weight: bold;
}

.activityName {
  font-weight: bold;
}

.goBackBtn {
  background-color: var(--secomp-yellow);
  border: none;
  border-radius: .5rem;

  font-family: "Montserrat";
  font-weight: bold;
  color: white;

  width: fit-content;
  padding: .875rem 1.5rem;

  transition: .2s;
}

.goBackBtn:hover {
  scale: 105%;
}