.subscribeContainer {
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

.background {
  width: 100%;
  max-height: 33%;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorImg {
  min-width: 200px;
  max-width: 450px;
}

.errorMessage {
  width: 100% ;
  color: white;

  text-align: center;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 1.125rem;

  margin-bottom: .5rem;
}

.errorLog {
  width: 100% ;
  color: white;

  text-align: center;
  text-decoration: underline;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 1.5rem;
}

@media (max-width: 1000px) {
  .errorImg {
    width: 70%;
  }
}

@media (max-width: 400px) {
  .errorMessage {
    font-size: .75rem;
  }

  .errorLog {
    font-size: 1.25rem;
  }
}