.subscribeForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  font-family: "Montserrat";
}

.subscribeForm h2 {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.formInput {
  padding: 0.5rem;
  width: 100%;

  font-size: 1.5rem;
  border: none;
  border-bottom: 4px solid var(--secomp-yellow);
}

.formInput:focus {
  outline: none;
}

.formInput::placeholder {
  color: var(--secomp-dark-blue)80;
}

.formInputError {
  padding: 0.5rem;
  width: 100%;

  font-size: 1.5rem;
  border: none;
  border-bottom: 4px solid var(--secomp-error);
}

.formInputError:focus {
  outline: none;
}

.formInputError::placeholder {
  color: var(--secomp-error);
}

.errorContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
}

.errorIcon {
  height: 0.75rem;
}

.errorMessage {
  display: block;
  color: var(--secomp-error);
  font-size: 0.75rem;
}

.warning {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.warningText {
  color: var(--secomp-yellow);
  font-size: 1.125rem;
}

.subscribeBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: 0.2s;

  padding: 0.75rem 1.5rem;
  min-width: 190px;

  border: none;
  font-size: 1.5rem;
  font-weight: bold;

  background-color: var(--secomp-yellow);
  color: white;
}

.subscribeBtn:hover {
  scale: 110%;
}

.subscribeBtn:focus {
  outline: none;
}

.loader {
  border: 4px solid white;
  border-top: 4px solid var(--secomp-dark-blue);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 900px) {
  .subscribeForm {
    min-width: none;
    min-width: 220px;
  }

  .formInput {
    width: 90%;
  }

}