.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 1rem .5rem;
  width: 329.43px;
  height: auto;
  background: #f3bc18;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  gap: 1rem;

  border: none;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.btn:hover {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 1.5);
  transform: translateY(-3px);
}

.btnImg {
  width: 9%;
  padding-left: 1rem;
}

.btnText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 25.47px;

  color: #ffffff;
}

@media (max-width: 400px) {
  .btn {
    width: auto;
    padding: 16px 10px;
    width: 250px;
  }

  .btnText {
    font-size: 16px;
  }
}
