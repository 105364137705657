.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  background-color: white;
  color: var(--secomp-dark-blue);
  border: .25rem solid var(--secomp-yellow);
  border-radius: .75rem;

  font-family: "Montserrat";
  font-weight: 300;
  font-size: .75rem;

  width: 256px;
  padding: 1rem;

  transition: .2s;
  cursor: pointer;
}

.cardContainerDisabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  background-color: white;
  background-color: #FFFFFF60;
  color: var(--secomp-dark-blue);
  border: .25rem solid var(--secomp-yellow);
  border-radius: .75rem;

  font-family: "Montserrat";
  font-weight: 300;
  font-size: .75rem;

  width: 256px;
  padding: 1rem;

  transition: .2s;
}

.cardContainer:hover,
.cardContainerDisabled:hover {
  scale: 105%;
}

.title {
  font-weight: bold;
  font-size: 1.125rem;
  text-align: center;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-height: 132px;
  overflow-y: auto;
  padding-right: 6px;
  width: 100%;
}

.descriptionContainer::-webkit-scrollbar {
  border-radius: 10px; 
  width: 6px;
}

.descriptionContainer::-webkit-scrollbar-thumb {
  background-color: var(--secomp-yellow); 
  border-radius: 10px; 
}

.slotsBadge {
  align-self: flex-start;

  background-color: var(--secomp-green);
  color: white;
  border-radius: 999px;

  font-weight: bold;

  padding: .25rem .75rem;
  width: fit-content;
}

.slotsBadgeEmpty {
  align-self: flex-start;

  background-color: var(--secomp-error);
  color: white;
  border-radius: 999px;

  font-weight: bold;

  padding: .25rem .75rem;
  width: fit-content;
}

.slotsBadgeDisabled {
  align-self: flex-start;

  background-color: var(--secomp-dark-blue);
  color: white;
  border-radius: 999px;

  font-weight: bold;

  padding: .25rem .75rem;
  width: fit-content;
}

.moreInfoBtn {
  display: flex;
  align-items: center;
  gap: .5rem;

  background-color: var(--secomp-yellow);
  color: white;
  border: none;
  border-radius: .75rem;
  padding: .25rem .5rem;
  padding-right: 1.125rem;

  font-weight: bold;
  text-align: left;

  cursor: pointer;
  transition: .2s;
}

.moreInfoBtn:hover {
  scale: 105%;
}

