.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 8rem;
}

.successfulContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  max-width: 344px;
  min-width: 232px;

  font-family: "Montserrat";
}

.icon {
  height: 96px;
  width: 96px;
}

.successfulTitle {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: var(--secomp-green);
}

.errorTitle {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: var(--secomp-error);
}

.successfulText {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 300;
  color: white;
}

.successfulBtn {
  cursor: pointer;
  transition: 0.2s;

  padding: 0.75rem 1.5rem;

  border: none;
  font-size: 1.5rem;
  font-weight: bold;

  background-color: var(--secomp-yellow);
  color: white;
}

.successfulBtn:hover {
  scale: 110%;
}

.successfulBtn:focus {
  outline: none;
  scale: 110%;
}

@media (max-width: 900px) {
  .content {
    flex-direction: column;
    gap: 2.5rem;
  }

  .successfulTitle {
    font-size: 1.5rem;
  }

  .successfulText {
    font-size: 1rem;
  }

}
