.programacao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  margin: 2rem 0;

  background: linear-gradient(180deg, #2d3e51 0%, rgba(45, 62, 81, 0) 100%);

  font-family: "Montserrat";
  font-style: normal;
  color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  margin-bottom: 1rem;
}

.programacaoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 95%;
  height: 530px;

  background: #354455;
  border-radius: 20px;
}

.dates {
  height: 530px;
  max-width: 430px;
  min-width: 360px;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  overflow-y: scroll;

  width: 66%;
  height: 430px;
  padding: 24px;

  background: #345d8f;
  box-shadow: 6px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 25px 7px 7px 25px;
}

.content::-webkit-scrollbar {
  width: 7px;
  height: calc(max-content - 100px);
}

/* Track */
.content::-webkit-scrollbar-track {
  background: #a9a996;
  border-radius: 0 20px 20px 0;
}

/* Handle */
.content::-webkit-scrollbar-thumb {
  background: #f3bc18;
  border-radius: 0 20px 20px 0;
  opacity: 0.5;
}

/* Handle on hover */
.content::-webkit-scrollbar-thumb:hover {
  background: #d9a300;
  border-radius: 0 20px 20px 0;
}

.btn {
  margin-top: 4%;
  align-self: center;
  justify-self: center;
}

@media (max-width: 1400px) {
  .dates {
    height: fit-content;
    padding-bottom: 30px;
  }

  .content {
    overflow-x: hidden;
  }
}

@media (max-width: 1080px) {
  .programacaoContainer {
    height: fit-content;
    flex-direction: column;
    justify-content: center;

    align-items: center;
  }

  .dates {
    display: flex;
    width: 100%;
    max-width: none;
  }
}

@media only screen and (max-width: 715px) {
  .content {
    padding: 10px;
  }
}

@media only screen and (max-width: 685px) {
  .content {
    height: 95%;
  }
  .dates {
    width: 95%;
    min-width: 0;
  }
}
