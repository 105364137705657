.hero {
  display: flex;
  justify-content: center;
  padding-top: 10rem;

  background: linear-gradient(
    180deg,
    #345d8f 42.4%,
    rgba(64, 120, 188, 0) 100%
  );
}

.content {
  display: flex;
  align-items: center;
  gap: 5%;

  width: 100vmax;
  max-width: 1200px;
  margin: 0 11rem;
}

.textIntro {
  display: flex;
  flex-direction: column;

  row-gap: 20px;

  color: #fff;
  font-style: normal;
  text-align: left;
}

.textTitle {
  display: flex;
  order: 0;
  flex-grow: 0;

  font-family: "Montserrat";
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.text {
  display: flex;
  flex-direction: column;
  flex: none;
  order: 1;
  flex-grow: 0;

  height: auto;
  margin: 0;

  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  white-space: pre-wrap;
}

.link {
  font-family: "Montserrat";
  display: inline-block;
  color: #ffffff;
  margin-top: 0.75rem;
  width: 100%;
}

.link:hover {
  color: #f3bc18;
}

.blockgroups {
  width: 40%;
  max-width: 590px;
  min-width: 400px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

@media only screen and (max-width: 940px) {
  .hero {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }
  .content {
    flex-direction: column;
    row-gap: 50px;
  }
  .textIntro {
    text-align: center;
    align-items: center;
  }
  .link {
    text-align: center;
  }
}

@media (max-width: 800px) {
  .hero {
    padding-top: 14rem;
  }
}

@media (max-width: 500px) {
  .intro {
    width: auto;
  }

  .blockgroups {
    width: 100%;
    min-width: 0;
  }
}
