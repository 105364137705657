.background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  inset: 0px;
  background-color: #00000080;
  backdrop-filter: blur(6px);

}

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  gap: 1rem;

  border: 0.25rem solid var(--secomp-yellow);
  border-radius: 0.75rem;
  padding: 2rem;
  width: 60dvw;
  max-width: 440px;
  min-width: 236px;

  font-family: "Montserrat";
  font-weight: 300;
  text-align: center;
  color: var(--secomp-dark-blue);
}

.closeBtn {
  position: absolute;
  right: 12px;
  top: 12px;
  align-self: flex-end;
  border: none;
  background-color: transparent;

  transition: .2s;
  cursor: pointer;
}

.closeBtn:hover {
  scale: 110%;
}

.closeBtn img {
  width: 28px;
  height: 28px;
}

.title {
  font-weight: bold;
  font-size: 2rem;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  
  text-align: left;
  font-size: 1rem;

  overflow-y: auto;
  max-height: 164px;
  width: 100%;
}

.descriptionContainer::-webkit-scrollbar {
  border-radius: 10px; 
  width: 6px;
}

.descriptionContainer::-webkit-scrollbar-thumb {
  background-color: var(--secomp-yellow); 
  border-radius: 10px; 
}

.eventData {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  border-left: var(--secomp-dark-blue) solid .25rem;
  padding-left: 1rem;

  text-align: left;
}

.needLaptop {
  font-weight: bold;
  color: var(--secomp-yellow);
}

.hidden {
  display: none;
}

.slotsBadge {
  align-self: flex-start;

  background-color: var(--secomp-green);
  color: white;
  border-radius: 999px;

  font-weight: bold;

  padding: 0.25rem 0.75rem;
  width: fit-content;
}

.slotsBadgeEmpty {
  align-self: flex-start;

  background-color: var(--secomp-error);
  color: white;
  border-radius: 999px;

  font-weight: bold;

  padding: 0.25rem 0.75rem;
  width: fit-content;
}

.formsTitle {
  font-weight: bold;
}

.formsSubtitle {
  align-self: left;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.formLabel {
  margin-bottom: -6px;
  align-self: flex-start;
}

.formInput {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;

  background-color: #d3dbe4;
}

.formInput::placeholder {
  font-family: "Montserrat";
  color: var(--secomp-dark-blue);
  font-size: .75rem;
}

.subscriptionWarning {
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 1rem;

  font-weight: bold;
  color: var(--secomp-yellow);
  text-align: left;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  padding: 0 26px;
  gap: 1rem;
}

.cancelBtn {
  font-family: "Montserrat";

  border: 0.125rem solid var(--secomp-yellow);
  border-radius: 0.5rem;

  color: var(--secomp-yellow);
  font-weight: bold;
  text-align: center;

  padding: 0.875rem 1.5rem;
  width: 50%;
  min-width: 7.5rem;

  transition: .2s;
  cursor: pointer;
}

.cancelBtn:hover {
  scale: 105%;
}

.confirmBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Montserrat";

  background-color: var(--secomp-yellow);
  border: none;
  border-radius: 0.5rem;

  color: white;
  font-weight: bold;
  text-align: center;

  padding: 0.875rem 1.5rem;
  width: 50%;
  min-width: 7.5rem;

  transition: .2s;
  cursor: pointer;
}

.confirmBtn:hover {
  scale: 105%;
}

.loader {
  border: 4px solid white;
  border-top: 4px solid var(--secomp-dark-blue);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.toast {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 1rem;
  width: 280px;
  border-radius: 999px;
}

@media (max-width: 600px) {
  .modalContainer {
    font-size: 0.75rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .buttonContainer {
    padding: 0;
    justify-content: center;
  }

  .closeBtn {
    right: 12px;
    top: 12px;
  }

  .closeBtn img {
    width: 1.5rem;
    height: 1.5rem;
  }
}
