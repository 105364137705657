/* Remoção do spinner em inputs number */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.subscribeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background {
  width: 100%;
  max-height: 243px;
}

.formSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;

  padding: 0 8rem;
}

.logoHorizontal {
  max-width: 704px;
  min-width: 196px;
}

.logoVertical {
  max-width: 704px;
  min-width: 196px;
  display: none;
}

.sponsorsContainer {
  width: 100%;
  background: linear-gradient(
    var(--secomp-light-blue),
    var(--secomp-dark-blue)
  );
}

.sponsorsContainerNoGradient {
  width: 100%;
  background-color: var(--secomp-dark-blue);
}

@media (max-width: 1400px) {
  .logoHorizontal {
    width: 55%;
  }
  .logoVertical {
    width: 55%;
  }
}

@media (max-width: 900px) {
  .formSection {
    flex-direction: column;
    gap: 2.5rem;
  }

  .logoVertical {
    display: block;
  }

  .logoHorizontal {
    display: none;
  }
}

@media (max-width: 600px) {
  .formSection {
    padding: 0 3rem;
  }
}
