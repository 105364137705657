.organizadores {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10rem 0;

  background: linear-gradient(180deg, #2d3e51 39.53%, #345d8f 99.09%);
}

.titulo {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 34px;
  line-height: 42px;
  color: #fff;
}

.datasContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  width: 90%;
}

.organizadoresContainer {
  display: flex;
  justify-content: center;
  padding: 2rem;

  width: 80%;

  background: #354455;
  border-radius: 20px;
}

.carouselContainer {
  display: flex;
}

@media (max-width: 1200px) {
  .organizadoresContainer {
      padding-bottom: 10%;
  }
}

@media (max-width: 940px) {
  .organizadores {
    padding: 50px 0;
  }

  .organizadoresContainer {
    padding-bottom: 20%;
  }
}

@media (max-width: 450px) {
  .organizadoresContainer {
    padding-bottom: 30%;
  }
}
