.sponsors {
  display: flex;
  align-items: center;
  gap: 2rem;

  margin: 0 auto;
  padding: 2rem 0;
  width: 100dvw;
  max-width: 750px;
}

.sponsorLogo {
  object-fit: none;
  width: fit-content;
  max-height: 128px;
}

.imcHorizontalLogo {
  display: none;
  max-width: 344px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .sponsorLogo {
    display: none;
  }

  .imcHorizontalLogo {
    display: block;
  }
}