.sponsorsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  font-size: 1.125rem;
  font-family: "Montserrat";
  font-weight: bold;

  background-color: var(--secomp-light-blue);
  width: 100%;
  padding: 4rem 0;
}

.title {
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  color: #fff;

  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.sponsorsContent {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.diamondSection {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.diamondTitle,
.goldTitle, 
.silverTitle,
.supporterTitle {
  display: block;
  text-align: center;

  font-size: 1.5rem;
  width: 20%;
}

.diamondTitle {
  color: #b9f2ff;
}

.diamondContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  border-left: #b9f2ff solid 0.25rem;
  background-color: #b9f2ff10;
  padding: 1.5rem 0;
  flex: 1;

  width: 100%;
}

.diamondContent img{
  width: 40%;
  transition: 0.2s;
  cursor: pointer;
}

.diamondContent img:hover{
  scale: 105%;
}

.goldSection {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.goldTitle {
  color: var(--secomp-yellow);
}

.goldContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;  

  border-left: var(--secomp-yellow) solid 0.25rem;
  background-color: #d7a91d10;
  padding: 1.5rem 0;
  flex: 1;

  width: 100%;
}

.goldContent img{
  width: 40%;
  transition: 0.2s;
  cursor: pointer;
}

.goldContent img:hover{
  scale: 105%;
}

.silverSection {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.silverTitle {
  color: #c0c0c0;
}

.silverContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  border-left: #c0c0c0 solid 0.25rem;
  background-color: #c0c0c010;
  padding: 1.5rem 0;
  flex: 1;

  width: 100%;
}

.silverContent img{
  width: 40%;
  max-height: 180px;
  object-fit: scale-down;
  transition: 0.2s;
  cursor: pointer;
}

.silverContent img:hover{
  scale: 105%;
}

.supporterSection {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.supporterTitle {
  color: #c47309;
}

.supporterContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  border-left: #c47309 solid 0.25rem;
  background-color: #c4730910;
  padding: 1.5rem 0;
  flex: 1;

  width: 100%;
}

.supporterContent img{
  width: 40%;
  max-height: 180px;
  object-fit: scale-down;
  transition: 0.2s;
  cursor: pointer;
}

.supporterContent img:hover{
  scale: 105%;
}

@media (max-width: 960px) {
  .sponsorsContent {
    width: 100%;
    gap: 32px;
  }

  .diamondSection,
  .goldSection,
  .silverSection,
  .supporterSection {
    flex-direction: column;
    width: 100%;
  }

  .diamondContent,
  .silverContent,
  .supporterContent {
    flex-direction: column;
    align-items: center;
  }

  .diamondContent,
  .goldContent,
  .silverContent,
  .supporterContent {
    border-left: none;
  }

  .diamondContent {
    border-top: #b9f2ff solid .25rem;
  }

  .goldContent {
    border-top: var(--secomp-yellow) solid .25rem;
  }

  .silverContent {
    border-top: #c0c0c0 solid .25rem;
  }

  .supporterContent {
    border-top: #c47309 solid .25rem;
  }

  .diamondTitle,
  .goldTitle, 
  .silverTitle,
  .supporterTitle {
    width: 100%;
  }

  .diamondContent img,
  .goldContent img,
  .silverContent img,
  .supporterContent img {
    min-width: 180px;
  }
}