.reminder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  font-family: "Montserrat";
  text-align: center;
  color: white;

  max-width: 1035px;
  padding: 2rem;
}

.arrow {
  width: 96px;
  height: 96px;
  position: relative;
  animation: down 1s infinite;
  -webkit-animation: down 1s infinite;
  &::before {
    content: "";
    position: absolute;
    top: 15px;
    left: 18px;
    width: 18px;
    height: 18px;
    border-left: 2px solid #333;
    border-bottom: 2px solid #333;
    transform: rotate(-45deg);
  }
}

.sigaaBtn {
  background-color: var(--secomp-yellow);
  border: none;

  font-size: 1.5rem;
  color: #fff;
  font-family: "Montserrat";
  font-weight: bold;

  transition: 0.2s;
}

.sigaaBtn:hover {
  scale: 105%;
}

.sigaaBtn a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 0.75rem 1.5rem;
}

.sigaaTutorial {
  color: #fff;
  font-weight: bold;

  transition: 0.2s;
}

.sigaaTutorial:hover {
  color: var(--secomp-yellow);
}

@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

.reminderTextSigaa {
  font-size: 1.5rem;
  font-weight: bold;
}

.reminderTextDescription {
  font-size: 1.5rem;
  font-weight: 300;
}

.reminderTextComeback {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secomp-yellow);
}

@media (max-width: 600px) {
  .reminderTextSigaa {
    font-size: 1rem;
  }

  .reminderTextXp {
    font-weight: 300;
    font-family: 1rem;
  }

  .reminderTextSubscribe {
    font-weight: bold;
    font-size: 1.5rem;
  }
}
